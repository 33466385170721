<template>
    <div class="users col-md-8 ml-sm-auto col-lg-9 px-4">
      <h1>Опросы</h1>
      <b-button-group class="head-group">
        <b-button class="add_first" variant="success" v-b-modal.modal-add-quiz>Добавить опрос</b-button>
        <b-button class="add_second" variant="success" v-b-modal.modal-add-question>Добавить вопрос</b-button>
      </b-button-group>
      <b-col lg="12" class="my-1">
        <b-form-group
          label="Фильтр"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Введите фразу/слово для поиска"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Очистить</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-table striped bordered responsive hover :fields="fieldsQuiz" :items="questions" table-variant="dark" v-if="questions">
        <template v-slot:cell(id)="data">
          <b-button variant="secondary" @click="delete_question(data.value)"><i class="fas fa-trash-alt"></i></b-button>
        </template>
      </b-table>

      <b-table striped bordered responsive hover :fields="fieldsResults" :items="results" table-variant="dark" v-if="results">
      </b-table>

      <b-table :filter="filter" responsive striped hover :fields="fields" :items="items" table-variant="dark">
        <template v-slot:cell(id)="data">
          <b-button variant="success" @click="get_questions(data.value)"><i class="fas fa-edit"></i></b-button>
          <b-button variant="secondary" @click="delete_item(data.value)"><i class="fas fa-trash-alt"></i></b-button>
        </template>
      </b-table>
      <modal-add @quiz="init"></modal-add>
      <modal-edit @quiz="init"></modal-edit>
      <modal-add-question @quiz="init"></modal-add-question>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import modalAdd from './ModalAdd';
import modalEdit from './ModalEdit';
import modalAddQuestion from './ModalAddQuestion';

export default {
  props: {},
  components: { modalAdd, modalEdit, modalAddQuestion },
  data() {
    return {
      filter: null,
      fields: [
        { key: 'name', label: 'Название опроса', sortable: true },
        { key: 'id', label: 'Управление', sortable: false },
      ],
      fieldsQuiz: [
        { key: 'question', label: 'Вопрос', sortable: false },
        { key: 'options', label: 'Варианты ответов', sortable: false },
        { key: 'id', label: 'Управление', sortable: false },
      ],
      fieldsResults: [
        { key: 'name', label: 'ФИО', sortable: false },
        { key: 'results', label: 'Ответы', sortable: false },
      ],
      questions: '',
      results: '',
    };
  },
  computed: {
    ...mapState({
      items: state => state.quizes.items,
      host: state => state.host,
    }),
  },
  methods: {
    // Component actions
    ...mapActions({
      initItems: 'quizes/initItems', // -> this.initItems()
      alertMsg: 'alertMsg', // -> this.alertMsg()
      initEditingItem: 'quizes/initEditingItem', // -> this.initEditingItem()
    }),
    
    // Initialize component
    init() {
      const path = this.host + 'quizes';
      axios.post(path, {}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.initItems({
              items: res.data.quizes
            })
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          if (error.response.status === 401){
            this.$store.dispatch('init', { email: localStorage.email, id: '', token: '' });
            this.$router.push('/login');
          }
        });
    },

    // Delete quiz from DB
    delete_item(item_id) {
      const path = this.host + 'delete_quiz';
      axios.post(path, {'id': item_id}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.init();
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
          } else {
            this.alertMsg({
              alert: res.data.error,
              type: 'danger',
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },

    // Shows questions of quiz
    get_questions(quiz_id) {
      const path = this.host + 'questions';
      axios.post(
        path, 
        {'id': quiz_id}, 
        { headers: 
          { Authorization: `Bearer ${localStorage.token}` } 
        }) 
        .then((res) => {
          if (res.data.status === 'success') { 
            this.questions = res.data.questions;
            this.get_results(quiz_id);
          } 
          else {
            this.alertMsg({
              alert: res.data.error,
              type: 'danger',
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        }
      );
    },

    // Shows results of quiz
    get_results(quiz_id) {
      const path = this.host + 'get_results';
      axios.post(
        path, 
        {'id': quiz_id}, 
        { headers: 
          { Authorization: `Bearer ${localStorage.token}` } 
        }) 
        .then((res) => {
          if (res.data.status === 'success') { 
            // str = str.replace(/[^a-zа-яё\s]/gi, '');
            this.results = res.data.response;
          } 
          else {
            this.alertMsg({
              alert: res.data.error,
              type: 'danger',
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        }
      );
    },

    // Opens edit modal
    edit_question(item_id) {
      this.initEditingItem({
        'index': item_id
      })
      this.$bvModal.show('modal-edit-question');
    },

    // Deletes question of quiz
    delete_question(question_id) {
      const path = this.host + 'delete_question';
      axios.post(path, {'id': question_id}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.init();
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
          } else {
            this.alertMsg({
              alert: res.data.error,
              type: 'danger',
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    }
  },
  created() {
    this.init();
  },
};
</script>

<style>
  .user-img{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center top;
  }
  .head-group{
    margin-bottom: 15px;
  }
  .add_second {
    margin-left: 15px !important;
  }
  tr > td:last-child, tr > th:last-child {
    text-align: right;  
  }
</style>
