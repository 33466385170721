<template>
    <div class="users col-md-8 ml-sm-auto col-lg-9 px-4">
      <h1>Группы контактов</h1>
      <b-button-group class="head-group">
        <b-button variant="success" v-b-modal.modal-add-contact-group>Добавить</b-button>
      </b-button-group>
      <b-col lg="12" class="my-1">
        <b-form-group
          label="Фильтр"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Введите фразу/слово для поиска"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Очистить</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-table v-sortable="sortableOptions" :busy="isBusy" id="contacts_table" :per-page="perPage" :current-page="currentPage" :filter="filter" striped hover :fields="fields" :items="items" table-variant="dark">
        <template v-slot:cell(id)="data">
          <span id="sort_val" style="visibility: hidden;">{{data.value}}</span>
          <b-button variant="success" @click="edit_item(data.value)" ref="btnEdit"><i class="fas fa-edit"></i></b-button>
          <b-button variant="secondary" @click="delete_item(data.value)"><i class="fas fa-trash-alt"></i></b-button>
        </template>
        <template v-slot:cell(photo)="data">
          <div class="user-img" :style="'background-image:url('+data.value+')'" alt=""></div>
        </template>
      </b-table>
      <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="contacts_table"
            first-number
            last-number
            align="center"
            v-if="rows>perPage"
      ></b-pagination>
      <modal-add @contact_groups="init"></modal-add>
      <modal-edit @contact_groups="init"></modal-edit>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import modalAdd from './ModalAdd';
import modalEdit from './ModalEdit';
import Sortable from 'sortablejs';

const createSortable = (el, options, vnode) => {
  // eslint-disable-next-line
  console.log(vnode);
  return Sortable.create(el, {
    ...options
  });
};

const sortable = {
  name: 'sortable',
  bind(el, binding, vnode) {
    const table = el;
    table._sortable = createSortable(table.querySelector('tbody'), binding.value, vnode);
  },
};

export default {
  directives: { sortable },
  props: {},
  components: {
    'modal-add': modalAdd,
    'modal-edit': modalEdit,
  },
  data() {
    return {
      sortableOptions: {
        chosenClass: 'is-selected',
        onEnd: function (evt) {
          var itemEl = evt.item;
          var newIndex = evt.newIndex;
          var identifier = itemEl.getElementsByTagName("td")[5].getElementsByTagName("span")[0].innerHTML;
          const path = 'https://lukoil.oppolukperm.ru/ajax/update_sort_contact';
          axios.post(path, {'id': identifier, 'sort': newIndex}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
          .then((res) => {
            // eslint-disable-next-line
            console.log(res);
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.log(error);
          });
        },
      },
      filter: null,
      perPage: 15,
      isBusy: false,
      currentPage: 1,
      fields: [
        { key: 'name', label: 'Имя', sortable: true },
        { key: 'id', label: '', sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      items: state => state.contact_groups.items,
      host: state => state.host,
    }),
    rows() {
      return this.items.length
    }
  },
  methods: {
    ...mapActions({
      initItems: 'contact_groups/initItems', // -> this.initItems()
      initEditingItem: 'contact_groups/initEditingItem', // -> this.initEditingItem()
      alertMsg: 'alertMsg', // -> this.alertMsg()
    }),
    init() {
      const path = this.host + 'contact_groups';
      axios.post(path, {}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.initItems({
              items: res.data.contact_groups
            })
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          if (error.response.status === 401){
            this.$store.dispatch('init', { email: localStorage.email, id: '', token: '' });
            this.$router.push('/login');
          }
        });
    },
    delete_item(item_id) {
      const path = this.host + 'delete_contact_group';
      axios.post(path, {'id': item_id}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.init();
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
          } else {
            this.alertMsg({
              alert: res.data.error,
              type: 'danger',
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    edit_item(item_id) {
      this.initEditingItem({
        'index': item_id
      })
      this.$bvModal.show('modal-edit-contact-group');
    },
  },
  created() {
    this.init();
  },
  mounted() {
    this.$root.$on('bv::modal::shown', () => {
      this.$nextTick(function () {
        // DOM updated
        window.dispatchEvent(new Event('resize'));
      });
    })
  },
};
</script>
