<template>
    <div class="users col-md-8 ml-sm-auto col-lg-9 px-4">
      <h1>Газеты/журналы</h1>
      <b-button-group class="head-group">
        <b-button class="add_first" variant="success" v-b-modal.modal-add-issue>Добавить выпуск</b-button>
        <b-button class="add_second" variant="success" v-b-modal.modal-add-media>Добавить газету/журнал</b-button>
      </b-button-group>
      <b-col lg="12" class="my-1">
        <b-form-group
          label="Фильтр"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Введите фразу/слово для поиска"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Очистить</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-table :busy="isBusy" id="contacts_table" :per-page="perPage" :current-page="currentPage" :filter="filter" responsive striped hover :fields="fields" :items="items" table-variant="dark">
        <template v-slot:cell(id)="data">
          <b-button variant="success" @click="edit_item(data.value)" ref="btnEdit"><i class="fas fa-edit"></i></b-button>
          <b-button variant="secondary" @click="delete_item(data.value)"><i class="fas fa-trash-alt"></i></b-button>
        </template>
      </b-table>
      <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="contacts_table"
            first-number
            last-number
            align="center"
            v-if="rows>perPage"
      ></b-pagination>
      <modal-add @issue="init"></modal-add>
      <modal-edit @issue="init"></modal-edit>
      <modal-add-media @issue="init"></modal-add-media>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import modalAdd from './ModalAdd';
import modalEdit from './ModalEdit';
import modalAddMedia from './ModalAddMedia';

export default {
  props: {
  },
  components: {
    modalAdd,
    modalEdit,
    modalAddMedia,
  },
  data() {
    return {
      filter: null,
      perPage: 15,
      isBusy: false,
      currentPage: 1,
      fields: [
        { key: 'name', label: 'Название выпуска', sortable: true },
        { key: 'link', label: 'Ссылка на выпуск', sortable: true },
        { key: 'mass_media', label: 'Газета/журнал', sortable: false },
        { key: 'id', label: '', sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      items: state => state.issues.items,
      host: state => state.host,
    }),
    rows() {
      return this.items.length;
    }
  },
  methods: {
    ...mapActions({
      initItems: 'issues/initItems', // -> this.initItems()
      initEditingItem: 'issues/initEditingItem', // -> this.initEditingItem()
      alertMsg: 'alertMsg', // -> this.alertMsg()
    }),
    init() {
      const path = this.host + 'mass_media';
      axios.post(path, {}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.initItems({
              items: res.data.issues
            })
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          if (error.response.status === 401){
            this.$store.dispatch('init', { email: localStorage.email, id: '', token: '' });
            this.$router.push('/login');
          }
        });
    },
    delete_item(item_id) {
      const path = this.host + 'delete_issue';
      axios.post(path, {'id': item_id}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.init();
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
          } else {
            this.alertMsg({
              alert: res.data.error,
              type: 'danger',
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    edit_item(item_id) {
      this.initEditingItem({
        'index': item_id
      })
      this.$bvModal.show('modal-edit-issue');
    },
  },
  created() {
    this.init();
  },
  mounted() {
    this.$root.$on('bv::modal::shown', () => {
      this.$nextTick(function () {
        // DOM updated
        window.dispatchEvent(new Event('resize'));
      });
    })
  },
};
</script>

<style>
  .user-img{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center top;
  }
  .head-group{
    margin-bottom: 15px;
  }
  .add_second {
    margin-left: 15px;
  }
</style>
