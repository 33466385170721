<template>
  <b-modal ref="editUnitModal"
           id="modal-edit-unit"
           title="Изменить подразделение"
           hide-footer>
      <b-form @submit="onSubmit" @reset="onReset" class="w-100">
      <alertMsg :message="alertMessage" v-if="showAlert"></alertMsg>
      <b-form-group id="form-title-group-name">
        <b-form-input id="name"
                        type="text"
                        placeholder="Введите название подразделения"
                        v-model="editForm.name"
                        required>

        </b-form-input>
      </b-form-group>
      <b-form-group id="form-title-group-short_name">
        <b-form-input id="short_name"
                        type="text"
                        placeholder="Введите короткое название"
                        v-model="editForm.short_name"
                        required>

        </b-form-input>
      </b-form-group>
      <b-form-group id="form-title-group-info">
        <b-form-textarea id="info"
                        type="text"
                        placeholder="Введите подробную информацию"
                        v-model="editForm.info"
                        required>

        </b-form-textarea>
      </b-form-group>
      <b-button type="submit" variant="success">Добавить</b-button>
      <b-button type="reset" variant="">Отмена</b-button>
    </b-form>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import alertMsg from '../common/AlertMsg';

export default {
  components: {
    alertMsg,
  },
  name: 'modalEdit',
  data() {
    return {
      editForm: {
        name: '',
        short_name: '',
        info: '',
      },
      alertMessage: 'Error',
      showAlert: false,
    };
  },
  computed: {
    ...mapState({
      host: state => state.host,
      editing_item: state => state.units.editing_item,
    }),
    isAuthorised() {
      return this.$store.getters.isAuthorised;
    },
  },
  methods: {
    ...mapActions([
      'alertMsg', // -> this.alertMsg()
    ]),
    editItem(payload) {
      this.showAlert = false;
      const id = 'modal-edit-unit';
      const path = this.host + 'edit_unit';
      axios.post(path, payload, { headers: { 'Authorization': `Bearer ${localStorage.token}`, 'Content-Type': 'multipart/form-data' } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.$bvModal.hide(id);
            this.$emit('units');
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      let payload = new FormData();
      payload.append('id', this.editing_item.id);
      payload.append('info', this.editForm.info);
      payload.append('short_name', this.editForm.short_name);
      payload.append('full_name', this.editForm.name);
      this.editItem(payload);
    },
    onReset(evt) {
      evt.preventDefault();
      this.$bvModal.hide('modal-edit-unit');
      this.initForm();
    },
    initForm() {
      this.editForm.short_name = this.editing_item.short_name;
      this.editForm.info = this.editing_item.info;
      this.editForm.name = this.editing_item.name;
    },
  },
  created() {
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'modal-edit-unit'){
        this.initForm();
      }
    })
  },
};
</script>

<style scoped>

</style>
