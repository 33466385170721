<template>
    <div class="users col-md-8 ml-sm-auto col-lg-9 px-4">
      <h1>Медиатека</h1>
      <b-button v-b-toggle.collapse-1 variant="success">Добавить событие</b-button>
      <b-collapse id="collapse-1" class="mt-2">
        <b-form @submit="onSubmit" @reset="onReset" class="w-100">
          <alertMsg :message="alertMessage" v-if="showAlert"></alertMsg>
          <div class="row">
            <b-form-group class="col-12 col-sm-6 col-md-4" id="form-title-group-name" label="Название события">
              <b-form-input id="name"
                              type="text"
                              placeholder="Введите название"
                              v-model="form.name"
                              required>
              </b-form-input>
            </b-form-group>
          </div>
          <div class="row">
            <b-form-group class="col-12 col-sm-6 col-md-4" id="form-title-group-name" label="Комментарий">
              <b-form-textarea id="comment"
                              type="text"
                              placeholder="Введите комментарий"
                              v-model="form.comment"
                              >
              </b-form-textarea>
            </b-form-group>
          </div>
          <div class="row">
            <b-form-group class="col-12 col-sm-6 col-md-4" id="form-title-group-sort" label="Порядок">
              <b-form-input id="sort"
                              type="number"
                              placeholder="Введите порядок (больше — выше)"
                              v-model="form.sort"
                              required>
              </b-form-input>
            </b-form-group>
          </div>
          <div class="row">
            <b-form-group class="col-12 col-sm-6 col-md-4" id="form-files" label="Фото (gif/jpg/jpeg/png/bmp) без ограничения на размер">
             <vue-upload-multiple-image
              @upload-success="uploadImageSuccess"
              @before-remove="beforeRemove"
              @edit-image="editImage"
              @data-change="dataChange"
              :data-images="images"
              dragText="Перетащите изображения"
              browseText="Или выберите"
              popupText="Загружено"
              primaryText=""
              markIsPrimaryText=""
              :maxImage="100"
              v-model="form.files"
              ></vue-upload-multiple-image>
            </b-form-group>
          </div>
           
          <b-button type="submit" variant="success">Отправить</b-button>
          <b-button v-b-toggle.collapse-1 type="reset" variant="">Отмена</b-button>
        </b-form>
      </b-collapse>

      <b-table :busy="isBusy" id="media_table" :per-page="perPage" :current-page="currentPage" :filter="filter" striped hover :fields="fields" :items="items" table-variant="dark">
        <template v-slot:cell(link)="data">
          <img class="user-img" :src="data.value" alt="">
        </template>
        <template v-slot:cell(id)="data">
          <span> {{data.value}} </span>
          <b-button v-b-toggle.collapse-1 variant="success" @click="edit_item(data.value)" ref="btnEdit"><i class="fas fa-edit"></i></b-button>
          <b-button variant="secondary" @click="delete_item(data.value)"><i class="fas fa-trash-alt"></i></b-button>
        </template>
      </b-table>
      <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="media_table"
            first-number
            last-number
            align="center"
            v-if="rows>perPage"
      ></b-pagination>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import alertMsg from '../common/AlertMsg';

export default {
  props: {
  },
  components: {
    VueUploadMultipleImage,
    alertMsg
  },
  data() {
    return {
      form: {
        name: '',
        files: '',
        link: '',
        comment: '',
        sort: '',
      },
      images: [],
      perPage: 15,
      isBusy: false,
      currentPage: 1,
      filter: null,
      edit: false,
      visible: false,
      showAlert: false,
      fields: [
        { key: 'link', label: 'Изображение', sortable: true },
        { key: 'name', label: 'Название', sortable: true },
        { key: 'comment', label: 'Комментарий', sortable: true },
        { key: 'sort', label: 'Порядок', sortable: true },
        { key: 'id', label: '', sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      user: state => state.id,
      host: state => state.host,
      items: state => state.media.items,
      editing_item: state => state.media.editing_item,
    }),
    isAuthorised() {
      return this.$store.getters.isAuthorised;
    },
    rows() {
      return this.items.length;
    }
  },
  methods: {
    ...mapActions({
      initItems: 'media/initItems', // -> this.initItems()
      initEditingItem: 'media/initEditingItem', // -> this.initEditingItem()
      alertMsg: 'alertMsg', // -> this.alertMsg()
    }),
    init() {
      const path = this.host + 'media';
      axios.post(path, {}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.initItems({
              items: res.data.media
            })
            // eslint-disable-next-line
            console.log(res.data.media)
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          if (error.response.status === 401){
            this.$store.dispatch('init', { email: localStorage.email, id: '', token: '' });
            this.$router.push('/login');
          }
        });
    },
    uploadImageSuccess(formData, index, fileList) {
      // eslint-disable-next-line
      console.log('data', formData, index, fileList)
      this.images = fileList;
    },
    beforeRemove (index, done, fileList) {
      // eslint-disable-next-line
      console.log('index', index, fileList)
      let removeBool = confirm("remove image")
      if (removeBool === true) { done(); }
    },
    editImage (formData, index, fileList) {
      // eslint-disable-next-line
      console.log('edit data', formData, index, fileList)
    },
    dataChange (data) {
      // eslint-disable-next-line
      console.log(data)
    },
    delete_item(item_id) {
      const path = this.host + 'delete_media';
      axios.post(path, {'id': item_id}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.init();
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
            // eslint-disable-next-line
            console.log(res.data.req);
          } else {
            this.alertMsg({
              alert: res.data.error,
              type: 'danger',
            });
            // eslint-disable-next-line
            console.log(res.data.req);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    edit_item(item_id) {
      this.initEditingItem({
        'index': item_id
      })
      this.initForm_edit();
    },
    
    visibilityChanged () {
      window.dispatchEvent(new Event('resize'));
    },
    addItem(payload) {
      this.showAlert = false;
      const path = this.host + 'add_mediatec';
      axios.post(path, payload, { headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.$root.$emit('bv::toggle::collapse', 'collapse-1');
            this.$emit('media');
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
            this.initForm();
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
            // eslint-disable-next-line
            console.log(res.data.req);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    add_pic(){
      //$('.last_img').css('background', '#eee')
    },
    editItem(payload) {
      this.showAlert = false;
      const path = this.host + 'edit_media';
      axios.post(path, payload, { headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.$root.$emit('bv::toggle::collapse', 'collapse-1');
            this.$emit('media');
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
            this.initForm();
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (this.edit){
        let payload = {
          'id' : this.editing_item.id,
          'name' : this.form.name,
          'comment' : this.form.comment,
          'sort' : this.form.sort,
          'files' : this.images
        };
        this.editItem(payload);
      }else{
        let payload = {
          'name' : this.form.name,
          'sort' : this.form.sort,
          'files' : this.images,
        };
        this.addItem(payload);
      }
      this.init();
    },
    onReset(evt) {
      evt.preventDefault();
      this.initForm();
    },
    initForm() {
      this.edit = false;
      this.form.name = '';
      this.form.sort = '';
    },
    initForm_edit() {
      this.edit = true;
      this.form.name = this.editing_item.name;
      this.form.sort = this.editing_item.sort;
    },
    initForm_add() {
      this.edit = false;
      this.form.name = '';
      this.form.sort = '';
    },
  },
  created() {
    this.init();
  },
  mounted() {
    this.$root.$on('bv::modal::shown', () => {
      this.$nextTick(function () {
        // DOM updated
        window.dispatchEvent(new Event('resize'));
      });
    })
  },
};
</script>