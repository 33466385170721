<template>
  <b-modal ref="editDocModal"
           id="modal-edit-doc"
           title="Изменить документ"
           hide-footer>
      <b-form @submit="onSubmit" @reset="onReset" class="w-100">
      <alertMsg :message="alertMessage" v-if="showAlert"></alertMsg>
      <b-form-group id="form-title-group-name">
        <b-form-input id="name"
                        type="text"
                        placeholder="Введите название"
                        v-model="editForm.name"
                        required>

        </b-form-input>
      </b-form-group>
      <b-form-group>
        <b-form-select v-model="editForm.type" :options="options"></b-form-select>
      </b-form-group>
      <b-form-group id="form-title-group-sort">
        <b-form-input id="sort"
                        type="number"
                        placeholder="Введите порядок (больше — выше)"
                        v-model="editForm.sort"
                        required>

        </b-form-input>
      </b-form-group>
      <b-form-group id="form-title-group-link">
        <b-form-input id="link"
                        type="text"
                        placeholder="Введите ссылку на файл"
                        v-model="editForm.link"
                        >

        </b-form-input>
      </b-form-group>
        или
      <b-form-group id="form-title-group-file">
        <b-form-file id="file"
                      v-model="editForm.file">

        </b-form-file>
      </b-form-group>
      <b-button type="submit" variant="success">Добавить</b-button>
      <b-button type="reset" variant="">Отмена</b-button>
    </b-form>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import alertMsg from '../common/AlertMsg';

export default {
  components: {
    alertMsg,
  },
  name: 'modalEdit',
  data() {
    return {
      editForm: {
        name: '',
        link: '',
        file: '',
        type: '',
        sort: '',
      },
      options: [
        { value: 0, text: 'Без категории' },
        { value: 1, text: 'Нормативные документы' },
        { value: 2, text: 'Образцы заявлений' },
        { value: 3, text: 'Другое' },
      ],
      alertMessage: 'Error',
      showAlert: false,
    };
  },
  computed: {
    ...mapState({
      host: state => state.host,
      editing_item: state => state.docs.editing_item,
    }),
    isAuthorised() {
      return this.$store.getters.isAuthorised;
    },
  },
  methods: {
    ...mapActions([
      'alertMsg', // -> this.alertMsg()
    ]),
    editItem(payload) {
      this.showAlert = false;
      const id = 'modal-edit-doc';
      const path = this.host + 'edit_doc';
      axios.post(path, payload, { headers: { 'Authorization': `Bearer ${localStorage.token}`, 'Content-Type': 'multipart/form-data' } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.$bvModal.hide(id);
            this.$emit('docs');
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      let payload = new FormData();
      payload.append('id', this.editing_item.id);
      payload.append('name', this.editForm.name);
      payload.append('link', this.editForm.link);
      payload.append('type', this.editForm.type);
      payload.append('sort', this.editForm.sort);
      payload.append('file', document.getElementById('file').files[0]);
      this.editItem(payload);
    },
    onReset(evt) {
      evt.preventDefault();
      this.$bvModal.hide('modal-edit-doc');
      this.initForm();
    },
    initForm() {
      this.editForm.name = this.editing_item.name;
      this.editForm.link = this.editing_item.link;
      this.editForm.type = this.editing_item.type;
      this.editForm.sort = this.editing_item.sort;
      this.editForm.file = '';
      //console.log(this.editing_item)
    },
  },
  created() {
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'modal-edit-doc'){
        this.initForm();
      }
    })
  },
};
</script>

<style scoped>

</style>
