<template>
  <nav class="navbar navbar-dark fixed-top bg-danger flex-md-nowrap p-0 shadow">
    <router-link class="navbar-brand col-md-4 col-lg-3 mr-0" to="/">{{App_name}}</router-link>
    <ul class="navbar-nav px-3" v-if="!isAuthorised">
      <b-nav-item id="login" @click="showModal" ref="btnShow">Вход</b-nav-item>
    </ul>
    <ul class="navbar-nav px-3" v-if="isAuthorised">
      <b-nav-item id="logout" @click="logout">Выход</b-nav-item>
    </ul>
  </nav>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';

export default {
  name: 'navbar',
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      App_name: state => state.header,
      token: state => state.token,
      host: state => state.host,
    }),
    isAuthorised() {
      return this.$store.getters.isAuthorised;
    },
  },
  methods: {
    logout() {
      const path = this.host + 'logout';
      axios.post(path, {}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.$store.commit('clear');
            this.$router.push('/login');
          } else {
            // eslint-disable-next-line
            console.log('Не получилось');
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    showModal() {
      this.$root.$emit('bv::show::modal', 'modal', '#btnShow');
    },
  },
};
</script>

<style scoped>

</style>
