<template>
  <b-modal ref="addItemModal"
           id="modal-edit-user"
           title="Редактировать пользователя"
           hide-footer>
      <b-form @submit="onSubmit" @reset="onReset" class="w-100">
      <alertMsg :message="alertMessage" v-if="showAlert"></alertMsg>
      <b-form-group id="form-title-group-name">
        <b-form-input id="name"
                        type="text"
                        placeholder="Введите Имя"
                        v-model="editForm.name"
                        required>

        </b-form-input>
      </b-form-group>
      <b-form-group id="form-title-group-patronymic">
        <b-form-input id="patronymic"
                        type="text"
                        placeholder="Введите отчество"
                        v-model="editForm.patronymic"
                        required>

        </b-form-input>
      </b-form-group>
      <b-form-group id="form-title-group-surname">
        <b-form-input id="surname"
                        type="text"
                        placeholder="Введите фамилию"
                        v-model="editForm.surname"
                        required>

        </b-form-input>
      </b-form-group>
      <b-form-group id="form-title-group-phone">
        <b-form-input id="phone"
                        type="tel"
                        placeholder="Введите телефон"
                        v-model="editForm.phone"
                        required>

        </b-form-input>
      </b-form-group>
      <b-form-group id="form-title-group-email">
        <b-form-input id="email"
                        type="email"
                        placeholder="Введите почту"
                        v-model="editForm.email"
                        required>

        </b-form-input>
      </b-form-group>
      <b-form-group id="form-title-group-pass">
        <b-form-input id="password"
                        type="password"
                        placeholder="Введите пароль"
                        v-model="editForm.password"
                        >

        </b-form-input>
      </b-form-group>
      <b-form-group id="form-title-group-pic">
        <picture-input 
          ref="pictureInput" 
          width="200" 
          height="200" 
          margin="8" 
          accept="image/jpeg,image/png" 
          size="10"
          :prefill="editForm.photo"
          :removable="true" 
          :customStrings="{
            upload: '<h1>Перетащите изображение</h1>',
            drag: 'Перетащите изображение',
            change: 'Заменить фото',
            remove: 'Удалить фото',
          }"
        >
        </picture-input>
      </b-form-group>
      <b-form-group id="form-title-group-unit" label="Подразделение">
        <b-form-select id="unit"
                        type="text"
                        placeholder="Введите код подразделения"
                        v-model="editForm.unit"
                        :options="units"
                        required>

        </b-form-select>
      </b-form-group>
      <b-form-group id="form-title-group-group" label="Группа">
        <b-form-select id="group"
                        type="text"
                        placeholder="Группа пользователей"
                        v-model="editForm.group"
                        :options="groups"
                        required>

        </b-form-select>
      </b-form-group>
      <b-button type="submit" variant="success">Изменить</b-button>
      <b-button type="reset" variant="">Отмена</b-button>
    </b-form>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import alertMsg from '../common/AlertMsg';
import PictureInput from 'vue-picture-input';

export default {
  components: {
    alertMsg,
    PictureInput,
  },
  name: 'modalEdit',
  data() {
    return {
      editForm: {
        email: '',
        password: '',
        name: '',
        patronymic: '',
        surname: '',
        phone: '',
        unit: '',
        group: '',
        photo: '',
        file: '',
      },
      alertMessage: 'Error',
      showAlert: false,
      units: [],
      groups: [],
    };
  },
  computed: {
    ...mapState({
      editing_item: state => state.users.editing_item,
      host: state => state.host,
    }),
    isAuthorised() {
      return this.$store.getters.isAuthorised;
    },
  },
  methods: {
    ...mapActions([
      'alertMsg', // -> this.alertMsg()
    ]),
    editItem(payload) {
      this.showAlert = false;
      const id = 'modal-edit-user';
      const path = this.host + 'edit_user';
      axios.post(path, payload, { headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.$bvModal.hide(id);
            this.$emit('users');
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    take_units() {
      this.showAlert = false;
      const path = this.host + 'take_units';
      axios.post(path, {}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.units = res.data.units;
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    take_groups() {
      this.showAlert = false;
      const path = this.host + 'take_groups';
      axios.post(path, {}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.groups = res.data.groups;
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      let payload = {
        'id' : this.editing_item.id,
        'email' : this.editForm.email,
        'password' : this.editForm.password,
        'name' : this.editForm.name,
        'patronymic' : this.editForm.patronymic,
        'surname' : this.editForm.surname,
        'phone' : this.editForm.phone,
        'unit' : this.editForm.unit,
        'group' : this.editForm.group,
      };
      if (this.$refs.pictureInput.file !== undefined){
        payload.photo = this.$refs.pictureInput.image;
        payload.photo_name = this.$refs.pictureInput.file.name;
      }
      this.editItem(payload);
    },
    onReset(evt) {
      evt.preventDefault();
      this.$bvModal.hide('modal-edit-user');
      this.initForm();
    },
    initForm() {
      this.editForm.email = this.editing_item.email;
      this.editForm.password = '';
      this.editForm.name = this.editing_item.name;
      this.editForm.patronymic = this.editing_item.patronymic;
      this.editForm.surname = this.editing_item.surname;
      this.editForm.phone = this.editing_item.phone;
      this.editForm.photo = this.editing_item.photo;
      this.units.forEach(function(unit) {
        if (unit.text === this.editing_item.unit){
          this.editForm.unit = unit.value;
        }
      },this);
      this.groups.forEach(function(group) {
        if (group.text === this.editing_item.role){
          this.editForm.group = group.value;
        }
      },this);
      if (this.editForm.photo) {
        // eslint-disable-next-line
        console.log(this.editForm.photo);
        //this.$refs.pictureInput.prefill = this.editForm.photo;
      }
    },
  },
  created() {
    this.take_groups();
    this.take_units();
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'modal-edit-user'){
        this.initForm();
      }
    })
  },
};
</script>

<style scoped>

</style>
