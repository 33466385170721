<template>
  <b-modal ref="addItemModal"
           id="modal-add-media"
           title="Добавить газету/журнал"
           hide-footer>
      <b-form @submit="onSubmit" @reset="onReset" class="w-100">
      <alertMsg :message="alertMessage" v-if="showAlert"></alertMsg>
      <b-form-group id="form-title-group-name" label="Наименование">
        <b-form-input id="name"
                        type="text"
                        placeholder="Введите название газеты/журнала"
                        v-model="addForm.name"
                        required>

        </b-form-input>
      </b-form-group>
      <b-form-group id="form-title-gaz-pic" label="Изображение">
        <picture-input ref="pictureInput" width="200" height="200" margin="8" accept="image/jpeg,image/png" size="10"
          :removable="true" :customStrings="{
          upload: '<h1>Перетащите изображение</h1>',
          drag: 'Перетащите изображение',
          change: 'Заменить фото',
          remove: 'Удалить фото',
        }">
        </picture-input>
      </b-form-group>
      <b-button type="submit" variant="success">Добавить</b-button>
      <b-button type="reset" variant="">Отмена</b-button>
    </b-form>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import alertMsg from '../common/AlertMsg';
import PictureInput from 'vue-picture-input';

export default {
  components: {
    alertMsg,
    PictureInput,
  },
  name: 'modalAddMedia',
  data() {
    return {
      addForm: {
        name: '',
        image: '',
      },
      alertMessage: 'Error',
      showAlert: false,
      groups: [],
    };
  },
  computed: {
    ...mapState({
      host: state => state.host,
    }),
    isAuthorised() {
      return this.$store.getters.isAuthorised;
    },
  },
  methods: {
    ...mapActions([
      'alertMsg', // -> this.alertMsg()
    ]),
    addItem(payload) {
      this.showAlert = false;
      const id = 'modal-add-media';
      const path = this.host + 'add_media';
      axios.post(path, payload, { headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.$bvModal.hide(id);
            this.$emit('issue');
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
            this.initForm();
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      let payload = {
        'name' : this.addForm.name,
        'image' : this.$refs.pictureInput.image,
        'image_name' : this.$refs.pictureInput.file.name
      };
      this.addItem(payload);
    },
    onReset(evt) {
      evt.preventDefault();
      this.$bvModal.hide('modal-add-media');
      this.initForm();
    },
    initForm() {
      this.addForm.name = '';
      this.addForm.image = '';
    },
  },
};
</script>

<style scoped>

</style>
