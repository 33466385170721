<template>
    <div class="users col-md-8 ml-sm-auto col-lg-9 px-4">
      <h1>Пользователи</h1>
      <b-button-group class="head-group">
        <b-button variant="success" v-b-modal.modal-add-user>Добавить</b-button>
      </b-button-group>
      <b-col lg="12" class="my-1">
        <b-form-group
          label="Фильтр"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Введите фразу/слово для поиска"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Очистить</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-table :busy="isBusy" id="users_table" :per-page="perPage" :current-page="currentPage" :filter="filter" striped hover :fields="fields" :items="items" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" table-variant="dark">
        <template v-slot:cell(id)="data">
          <b-button variant="success" @click="edit_item(data.value)" ref="btnEdit"><i class="fas fa-edit"></i></b-button>
          <b-button v-if="user!=data.value" variant="secondary" @click="delete_item(data.value)"><i class="fas fa-trash-alt"></i></b-button>
          <b-button v-if="data.item.is_active==1" variant="danger" @click="switch_active(data.value)"><i class="fas fa-minus"></i></b-button>
          <b-button v-if="data.item.is_active==0" variant="success" @click="switch_active(data.value)"><i class="fas fa-plus"></i></b-button>
        </template>
        <template v-slot:cell(photo)="data">
          <div class="user-img" :style="'background-image:url('+data.value+')'" alt=""></div>
        </template>
        <template v-slot:cell(name)="data">
          <div :id="'tooltip-target-'+data.index">{{data.item.name + ' ' + data.item.patronymic + ' ' + data.item.surname}}</div>
          <b-tooltip :target="'tooltip-target-'+data.index" triggers="hover">
            Последний вход: {{ new Date(data.item.last_login).toLocaleString("ru") }},
            Последнее действие: {{ new Date(data.item.last_action).toLocaleString("ru") }}
          </b-tooltip>
        </template>
      </b-table>
      <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="users_table"
            first-number
            last-number
            align="center"
            v-if="rows>perPage"
      ></b-pagination>
      <modal-add @users="init"></modal-add>
      <modal-edit @users="init"></modal-edit>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import modalAdd from './ModalAdd';
import modalEdit from './ModalEdit';

export default {
  props: {
  },
  components: {
    'modal-add': modalAdd,
    'modal-edit': modalEdit,
  },
  data() {
    return {
      sortBy: 'name',
      sortDesc: false,
      filter: null,
      perPage: 15,
      isBusy: false,
      currentPage: 1,
      fields: [
        { key: 'photo', label: '', sortable: true },
        { key: 'name', label: 'Имя', sortable: true },
        { key: 'email', label: 'Почта', sortable: true },
        { key: 'role', label: 'Роль', sortable: true },
        { key: 'unit', label: 'Подразделение', sortable: true },
        { key: 'id', label: '', sortable: false }
      ],
    };
  },
  computed: {
    ...mapState({
      user: state => state.id,
      items: state => state.users.items,
      host: state => state.host,
    }),
    rows() {
      return this.items.length
    }
  },
  methods: {
    ...mapActions({
      initItems: 'users/initItems', // -> this.initItems()
      initEditingItem: 'users/initEditingItem', // -> this.initEditingItem()
      alertMsg: 'alertMsg', // -> this.alertMsg()
    }),
    init() {
      const path = this.host + 'users';
      axios.post(path, {}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.initItems({
              items: res.data.users
            })
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          if (error.response.status === 401){
            this.$store.dispatch('init', { email: localStorage.email, id: '', token: '' });
            this.$router.push('/login');
          }
        });
    },
    delete_item(item_id) {
      const path = this.host + 'delete_user';
      axios.post(path, {'id': item_id}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.init();
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
          } else {
            this.alertMsg({
              alert: res.data.error,
              type: 'danger',
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    edit_item(item_id) {
      this.initEditingItem({
        'index': item_id
      })
      this.$bvModal.show('modal-edit-user');
    },
    switch_active(item_id) {
      const path = this.host + 'switch_active';
      axios.post(path, {'id': item_id}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.init();
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
          } else {
            this.alertMsg({
              alert: res.data.error,
              type: 'danger',
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
  },
  created() {
    this.init();
  },
  mounted() {
    this.$root.$on('bv::modal::shown', () => {
      this.$nextTick(function () {
        // DOM updated
        window.dispatchEvent(new Event('resize'));
      });
    })
  },
};
</script>

<style>
  .user-img{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-size: contain;
    background-position: center top;
  }
  .head-group{
    margin-bottom: 15px;
  }
</style>
