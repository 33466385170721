<template>
  <div>
    <b-alert variant="danger" show>{{ message }}</b-alert>
    <br>
  </div>
</template>

<script>
export default {
  props: ['message'],
  name: 'alertMsg',
};
</script>
