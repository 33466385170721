<template>
  <div class="files col-12">
      <h3>Файлы</h3>
      <b-form @submit="onSubmit" @reset="onReset" class="w-100">
        <alertMsg :message="alertMessage" v-if="showAlert"></alertMsg>
        <b-form-group id="form-name">
          <b-form-input id="file_name"
                          type="text"
                          placeholder="Введите название"
                          v-model="addFile.name"
                          required>

          </b-form-input>
        </b-form-group>
        <b-form-group id="form-link">
          <b-form-input id="file_link"
                          type="text"
                          placeholder="Введите ссылку на файл"
                          v-model="addFile.link"
                          >

          </b-form-input>
        </b-form-group>
          или
        <b-form-group id="form-file">
          <b-form-file id="file"
                        v-model="addFile.file">

          </b-form-file>
        </b-form-group>
        <b-button type="submit" variant="success">Добавить файл</b-button>
      </b-form>
      <b-col lg="12" class="my-1">
        <b-form-group
          label-cols-sm="0"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <!--
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Введите фразу/слово для поиска"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Очистить</b-button>
            </b-input-group-append>
          </b-input-group>
          -->
        </b-form-group>
      </b-col>
      <div class="row styled_row">
        <b-table
            small
            :filter="filter"
            striped
            :busy="isBusy"
            hover
            :fields="fields"
            :items="items"
            table-variant="dark"
            responsive
            :per-page="perPage" 
            :current-page="currentPage"
            id="files_table"
            >
          <template v-slot:cell(link)="data">
            <b-button variant="success" @click="copy_item(data.value)" ref="btnEdit"><i class="fas fa-clipboard"></i></b-button>
          </template>
        </b-table>
        <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="files_table"
            first-number
            last-number
            align="center"
            v-if="rows>perPage"
        >
          
        </b-pagination>
      </div>
      
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import alertMsg from '../common/AlertMsg';
import axios from 'axios';
export default {
  components: {
    alertMsg,
  },
  name: 'files-table',
  data() {
    return {
      perPage: 5,
      currentPage: 1,
      addFile: {
        name: '',
        link: '',
        file: null,
      },
      isBusy: false,
      filter: null,
      showAlert: false,
      fields: [
        { key: 'name', label: 'Название', sortable: true },
        { key: 'link', label: 'Ссылка', sortable: true },
      ],
    };
  },
  computed: {
    ...mapState({
      items: state => state.files.items,
      host: state => state.host,
    }),
    rows() {
      return this.items.length
    }
  },
  methods: {
    ...mapActions({
      initItems: 'files/initItems', // -> this.initItems()
      initEditingItem: 'files/initEditingItem', // -> this.initEditingItem()
      alertMsg: 'alertMsg', // -> this.alertMsg()
    }),
    init() {
      const path = this.host + 'files';
      axios.post(path, {}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.isBusy = true;
            this.initItems({
              items: res.data.files
            });
            this.isBusy = false;
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((/*error*/) => {
          // eslint-disable-next-line
          // if (error.response.status === 401){
          //   this.$store.dispatch('init', { email: localStorage.email, id: '', token: '' });
          //   this.$router.push('/login');
          // }
        });
    },
    addItem(payload) {
      this.showAlert = false;
      const path = this.host + 'add_file';
      axios.post(path, payload, { headers: { 'Authorization': `Bearer ${localStorage.token}`, 'Content-Type': 'multipart/form-data' } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.$emit('docs');
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
            this.init();
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      let payload = new FormData();
      payload.append('name', this.addFile.name);
      payload.append('link', this.addFile.link);
      payload.append('file', document.getElementById('file').files[0]);
      this.addItem(payload);
      this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.initForm();
    },
    copy_item(item_link) {
      navigator.clipboard.writeText(item_link)
      .then(() => {
        this.alertMsg({
          alert: 'Ссылка скопирована в буфер обмена!',
          type: 'success',
        });
      })
          // eslint-disable-next-line
      .catch(err => {
        this.alertMsg({
          alert: 'Что-то пошло не так...',
          type: 'err',
        });
      });
      
    },
    initForm() {
      this.addFile.name = '';
      this.addFile.link = '';
      this.addFile.file = '';
    },
  },
  
  created() {
    this.init();
  },
};
</script>

<style>
  .custom-file-label{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .files{
    background: #484c50;
    padding: 15px;
  }
  #files_table {
    margin-top: 20px;
  }
  .styled_row {
    justify-content: center;
  }
</style>
