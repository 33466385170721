import Vue from 'vue';
import Router from 'vue-router';
import users from '@/components/user/Users';
import units from '@/components/unit/Units';
import faq from '@/components/faq/Faq';
import docs from '@/components/doc/Doc';
import news from '@/components/news/News';
import actual_news from '@/components/actual_news/ActualNews';
import media from '@/components/media/Media';
import contacts from '@/components/contacts/Contacts';
import contact_groups from '@/components/contact_groups/ContactGroups';
import stats from '@/components/common/Stats';
import login from '@/components/common/Login';
import massmedia from '@/components/massmedia/Massmedia';
import events from '@/components/events/Events';
import quiz from '@/components/quiz/Quiz';
import store from '@/store/store';

Vue.use(Router);

const isAuthorised = (to, from, next) => {
  if (store.getters.isAuthorised) {
    next()
    return
  }
  next('/login')
}

const isNotAuthorised = (to, from, next) => {
  if (!store.getters.isAuthorised) {
    next()
    return
  }
  next('/')
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Статистика',
      component: stats,
      beforeEnter: isAuthorised,
    },
    {
      path: '/users',
      name: 'Пользователи',
      component: users,
      beforeEnter: isAuthorised,
    },
    {
      path: '/units',
      name: 'Подразделения',
      component: units,
      beforeEnter: isAuthorised,
    },
    {
      path: '/faq',
      name: 'ЧаВО',
      component: faq,
      beforeEnter: isAuthorised,
    },
    {
      path: '/docs',
      name: 'Документы',
      component: docs,
      beforeEnter: isAuthorised,
    },
    {
      path: '/news',
      name: 'Новости',
      component: news,
      beforeEnter: isAuthorised,
    },
    {
      path: '/actual_news',
      name: 'Объявления',
      component: actual_news,
      beforeEnter: isAuthorised,
    },
    {
      path: '/login',
      name: 'Не доступен',
      component: login,
      beforeEnter: isNotAuthorised,
    },
    {
      path: '/media',
      name: 'Медиатека',
      component: media,
      beforeEnter: isAuthorised,
    },
    {
      path: '/contacts',
      name: 'Контакты',
      component: contacts,
      beforeEnter: isAuthorised,
    },
    {
      path: '/contact_groups',
      name: 'Группы контактов',
      component: contact_groups,
      beforeEnter: isAuthorised,
    },
    {
      path: '/massmedia',
      name: 'Газеты/журналы',
      component: massmedia,
      beforeEnter: isAuthorised,
    },
    {
      path: '/events',
      name: 'Мероприятия',
      component: events,
      beforeEnter: isAuthorised,
    },
    {
      path: '/quiz',
      name: 'Опросы',
      component: quiz,
      beforeEnter: isAuthorised,
    },
  ],
});
