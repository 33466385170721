<template>
    <b-col cols="9" md="10" class="stats ml-sm-auto px-4 text-center">
      <h1>Вам нужно авторизоваться!</h1> <b-button variant="success" @click="showModal">Войти</b-button>
    </b-col>
</template>

<script>
//import axios from 'axios';
import { mapState } from 'vuex';

export default {
  name: 'stats',
  components: {
  },
  data() {
    return {
      options: {
        responsive: true, maintainAspectRatio: false, scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
      },
      news_data: [],
      items: [],
      labels: [],
      data: {},
    }
  },
  computed: {
    ...mapState({
      host: state => state.host,
    }),
    isAuthorised() {
      return this.$store.getters.isAuthorised;
    },
  },
  methods: {
    init(){
      //console.log(this.isAuthorised)
      if (this.isAuthorised){
        this.$router.push('/');
      }
    },
    showModal() {
      this.$root.$emit('bv::show::modal', 'modal', '#btnShow');
    },
  },
  activated() {
    this.init()
  }
};
</script>

<style scoped>
.stat-info .content {
  margin: 10px -5px;
  box-shadow: 0 0 10px #e4e4e4;
  padding: 25px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stat-info .content .header{
  font-weight: light;
  font-size: 1.5em;
}
.stat-info .content .value{
  font-weight: bold;
  color: #dc3545;
  font-size: 2em;
}
.stat-info .content .value.chart{
  width: 100%
}
.stat-info .content hr{
  margin: 5px;
  border-top: 2px solid rgb(247, 12, 12);
  width: 25%;
}
</style>
