<template>
  <b-modal ref="editfaqModal"
           id="modal-edit-faq"
           title="Изменить вопрос"
           hide-footer>
      <b-form @submit="onSubmit" @reset="onReset" class="w-100">
      <alertMsg :message="alertMessage" v-if="showAlert"></alertMsg>

      <b-form-group id="form-title-group-question">
        <b-form-textarea id="question"
                        type="text"
                        placeholder="Введите вопрос"
                        v-model="editForm.question"
                        required>

        </b-form-textarea>
      </b-form-group>
      <b-form-group id="form-title-group-answer">
        <b-form-textarea id="answer"
                        type="text"
                        placeholder="Введите ответ"
                        v-model="editForm.answer"
                        required>

        </b-form-textarea>
      </b-form-group>
      <b-form-group id="form-title-group-tags">
        <b-form-input id="tags"
                        type="text"
                        placeholder="Введите тэги (через пробел)"
                        v-model="editForm.tags"
                        >

        </b-form-input>
      </b-form-group>
      <b-button type="submit" variant="success">Добавить</b-button>
      <b-button type="reset" variant="">Отмена</b-button>
    </b-form>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import alertMsg from '../common/AlertMsg';

export default {
  components: {
    alertMsg,
  },
  name: 'modalEdit',
  data() {
    return {
      editForm: {
        question: '',
        answer: '',
        tags: '',
      },
      alertMessage: 'Error',
      showAlert: false,
    };
  },
  computed: {
    ...mapState({
      editing_item: state => state.faq.editing_item,
      host: state => state.host,
    }),
    isAuthorised() {
      return this.$store.getters.isAuthorised;
    },
  },
  methods: {
    ...mapActions([
      'alertMsg', // -> this.alertMsg()
    ]),
    editItem(payload) {
      this.showAlert = false;
      const id = 'modal-edit-faq';
      const path = this.host + 'edit_faq';
      axios.post(path, payload, { headers: { 'Authorization': `Bearer ${localStorage.token}`, 'Content-Type': 'multipart/form-data' } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.$bvModal.hide(id);
            this.$emit('faqs');
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      let payload = new FormData();
      payload.append('id', this.editing_item.id);
      payload.append('answer', this.editForm.answer);
      payload.append('question', this.editForm.question);
      payload.append('tags', this.editForm.tags);
      this.editItem(payload);
    },
    onReset(evt) {
      evt.preventDefault();
      this.$bvModal.hide('modal-edit-faq');
      this.initForm();
    },
    initForm() {
      this.editForm.question = this.editing_item.question;
      this.editForm.answer = this.editing_item.answer;
      this.editForm.tags = this.editing_item.tags;
    },
  },
  created() {
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'modal-edit-faq'){
        this.initForm();
      }
    })
  },
};
</script>

<style scoped>

</style>
