import { Line, mixins } from 'vue-chartjs';

export default{
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'options'],
  mounted () {
    this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);
    this.gradient.addColorStop(0, 'rgba(255, 0,0, 0.8)');
    this.gradient.addColorStop(0.5, 'rgba(255, 0, 0, 0.4)');
    this.gradient.addColorStop(1, 'rgba(255, 0, 0, 0.1)');
    this.renderChart(this.chartData, this.options)
  }
}
