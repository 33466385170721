<template>
    <div class="users col-md-8 ml-sm-auto col-lg-9 px-4">
      <h1>Мероприятия</h1>
      <b-button-group class="head-group">
        <b-button class="add_first" variant="success" v-b-modal.modal-add-event>Добавить мероприятие</b-button>
      </b-button-group>
      <b-col lg="12" class="my-1">
        <b-form-group
          label="Фильтр"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Введите фразу/слово для поиска"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Очистить</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-table striped bordered responsive hover :fields="fieldsReq" :items="requests" table-variant="dark" v-if="requests">
        <template v-slot:cell(id)="data">
          <b-button variant="success" @click="req_accept(data.value)"><i class="fas fa-arrow-up"></i></b-button>
          <b-button variant="secondary" @click="req_decline(data.value)"><i class="fas fa-arrow-down"></i></b-button>
        </template>
      </b-table>

      <b-table :filter="filter" responsive striped hover :fields="fields" :items="items" table-variant="dark">
        <template v-slot:cell(id)="data">
          <b-button variant="success" @click="get_requests(data.value)"><i class="fas fa-edit"></i></b-button>
          <b-button variant="secondary" @click="delete_item(data.value)"><i class="fas fa-trash-alt"></i></b-button>
        </template>
      </b-table>
      
      <modal-add @events="init"></modal-add>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import modalAdd from './ModalAdd';

export default {
  props: {},
  components: { modalAdd },
  data() {
    return {
      filter: null,
      fields: [
        { key: 'name', label: 'Название мероприятия', sortable: true },
        { key: 'id', label: 'Управление', sortable: false },
      ],
      fieldsReq: [
        { key: 'name', label: 'Имя', sortable: true },
        { key: 'status', label: 'Статус', sortable: true },
        { key: 'id', label: 'Управление', sortable: false },
      ],
      requests: '',
    };
  },
  computed: {
    ...mapState({
      items: state => state.events.items,
      host: state => state.host,
    }),
  },
  methods: {
    // Component actions
    ...mapActions({
      initItems: 'events/initItems', // -> this.initItems()
      initEditingItem: 'events/initEditingItem', // -> this.initEditingItem()
      alertMsg: 'alertMsg', // -> this.alertMsg()
    }),

    // Initialize component
    init() {
      const path = this.host + 'events';
      axios.post(path, {}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.initItems({
              items: res.data.events
            })
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          if (error.response.status === 401){
            this.$store.dispatch('init', { email: localStorage.email, id: '', token: '' });
            this.$router.push('/login');
          }
        });
    },

    // Delete event from DB
    delete_item(item_id) {
      const path = this.host + 'delete_event';
      axios.post(path, {'id': item_id}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.init();
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
          } else {
            this.alertMsg({
              alert: res.data.error,
              type: 'danger',
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },

    // Get all requests of event
    get_requests(event_id) {
      const path = this.host + 'view_req';
      axios.post(
        path, 
        {'id': event_id}, 
        { headers: 
          { Authorization: `Bearer ${localStorage.token}` } 
        }) 
        .then((res) => {
          if (res.data.status === 'success') { 
            this.requests = res.data.reqs;
          } 
          else {
            this.alertMsg({
              alert: res.data.error,
              type: 'danger',
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },

    // Accept event request
    req_accept(user_id) {
      const path = this.host + 'accept_request';
      axios.post(
        path, 
        {
          'id': user_id,
        }, 
        { headers: 
          { Authorization: `Bearer ${localStorage.token}` } 
        }) 
        .then((res) => {
          if (res.data.status === 'success') { 
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
          } 
          else {
            this.alertMsg({
              alert: res.data.error,
              type: 'danger',
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },

    // Decline event request
    req_decline(user_id) {
      const path = this.host + 'decline_request';
      axios.post(
        path, 
        {
          'id': user_id,
        }, 
        { headers: 
          { Authorization: `Bearer ${localStorage.token}` } 
        }) 
        .then((res) => {
          if (res.data.status === 'success') { 
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
          } 
          else {
            this.alertMsg({
              alert: res.data.error,
              type: 'danger',
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    }
  },
  created() {
    this.init();
  },
};
</script>

<style>
  .user-img{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center top;
  }
  .head-group{
    margin-bottom: 15px;
  }
  .add_second {
    margin-left: 15px;
  }
  tr > td:last-child, tr > th:last-child {
    text-align: right;  
  }
</style>
