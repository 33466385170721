// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'bootstrap/dist/css/bootstrap.css';
import BootstrapVue from 'bootstrap-vue';
import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store/store.js';
import VueObserveVisibility from 'vue-observe-visibility'

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VueObserveVisibility)

store.dispatch('init', { email: localStorage.email, id: localStorage.id, token: localStorage.token });

/* eslint-disable no-new */
new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')