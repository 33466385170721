<template>
  <b-modal ref="loginModal"
           id="modal"
           title="Форма входа"
           hide-footer
           v-if="!isAuthorised">
      <b-form @submit="onSubmit" @reset="onReset" class="w-100">
      <alertMsg :message="alertMessage" v-if="showAlert"></alertMsg>
      <b-form-group id="form-title-group-email">
        <b-form-input id="email"
                        type="email"
                        placeholder="Введите ваш email"
                        v-model="loginForm.email"
                        :value="email"
                        required>

        </b-form-input>
      </b-form-group>
      <b-form-group id="form-title-group-pass">
        <b-form-input id="password"
                        type="password"
                        placeholder="Введите ваш пароль"
                        v-model="loginForm.password"
                        required>

        </b-form-input>
      </b-form-group>
      <b-button type="submit" variant="success">Войти</b-button>
      <b-button type="reset" variant="">Отмена</b-button>
    </b-form>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import alertMsg from './AlertMsg';

export default {
  components: {
    alertMsg,
  },
  name: 'modal-login',
  data() {
    return {
      loginForm: {
        email: localStorage.email,
        password: '',
      },
      alertMessage: 'Error',
      showAlert: false,
    };
  },
  computed: {
    ...mapState({
      email: state => state.email,
      token: state => state.token,
      host: state => state.host,
    }),
    isAuthorised() {
      return this.$store.getters.isAuthorised;
    },
  },
  methods: {
    login(payload) {
      this.showAlert = false;
      const id = 'modal';
      const path = this.host + 'login';
      axios.post(path, payload)
        .then((res) => {
          if (res.data.status === 'success') {
            localStorage.email = payload.email;
            localStorage.id = res.data.user;
            localStorage.token = res.data.token;
            this.$store.commit('init', { email: localStorage.email, id: localStorage.id, token: localStorage.token });
            this.$bvModal.hide(id);
            this.$router.push('/');
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      const payload = {
        email: this.loginForm.email,
        password: this.loginForm.password,
      };
      this.login(payload);
      this.initForm();
    },
    onReset(evt) {
      evt.preventDefault();
      this.$bvModal.hide('modal');
      this.initForm();
    },
    initForm() {
      this.loginForm.email = localStorage.email;
      this.loginForm.password = '';
    },
  },
};
</script>

<style scoped>

</style>
