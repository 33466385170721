<template>
    <div class="units col-md-8 ml-sm-auto col-lg-9 px-4">
      <h1>Подразделения</h1>
      <b-button-group class="head-group">
        <b-button variant="success" v-b-modal.modal-add-unit>Добавить</b-button>
      </b-button-group>
      <b-col lg="12" class="my-1">
        <b-form-group
          label="Фильтр"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Введите фразу/слово для поиска"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Очистить</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-table :filter="filter" striped hover :fields="fields" :items="items" table-variant="dark">
        <template v-slot:cell(id)="data">
          <b-button variant="success" @click="edit_item(data.value)" ref="btnEdit"><i class="fas fa-edit"></i></b-button>
          <b-button variant="secondary" @click="delete_item(data.value)"><i class="fas fa-trash-alt"></i></b-button>
        </template>
      </b-table>
      <modal-add @units="init"></modal-add>
      <modal-edit @units="init"></modal-edit>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import modalAdd from './ModalAdd';
import modalEdit from './ModalEdit';

export default {
  props: {
  },
  components: {
    'modal-add': modalAdd,
    'modal-edit': modalEdit,
  },
  data() {
    return {
      filter: null,
      fields: [
        { key: 'name', label: 'Название', sortable: true },
        { key: 'short_name', label: 'Короткое называние', sortable: true },
        { key: 'code', label: 'Код', sortable: true },
        { key: 'info', label: 'Информация', sortable: true },
        { key: 'regs', label: 'Регистраций', sortable: true },
        { key: 'id', label: '', sortable: false }
      ],
    };
  },
  computed: {
    ...mapState({
      host: state => state.host,
      items: state => state.units.items
    }),
  },
  methods: {
    ...mapActions({
      initItems: 'units/initItems', // -> this.initItems()
      initEditingItem: 'units/initEditingItem', // -> this.initEditingItem()
      alertMsg: 'alertMsg', // -> this.alertMsg()
    }),
    init() {
      const path = this.host + 'units';
      axios.post(path, {}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.initItems({
              items: res.data.units
            })
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
            this.units = []
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          if (error.response.status === 401){
            this.$store.dispatch('init', { email: localStorage.email, id: '', token: '' });
            this.$router.push('/login');
          }
        });
    },
    delete_item(item_id) {
      const path = this.host + 'delete_unit';
      axios.post(path, {'id': item_id}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.init();
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
          } else {
            this.alertMsg({
              alert: res.data.error,
              type: 'danger',
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    edit_item(item_id) {
      this.initEditingItem({
        'index': item_id
      })
      this.$bvModal.show('modal-edit-unit');
    },
  },
  created() {
    this.init();
  },
};
</script>

<style>
  .head-group{
    margin-bottom: 15px;
  }
</style>
