<template>
    <div class="users col-md-8 ml-sm-auto col-lg-9 px-4">
      <h1>Новости</h1>
      <b-button @click="visible = !visible" variant="success">Добавить новость</b-button>
      <b-collapse id="collapse-1" v-model="visible" class="mt-2">
        <b-form @submit="onSubmit" @reset="onReset" class="w-100">
          <alertMsg :message="alertMessage" v-if="showAlert"></alertMsg>
          <div class="row">
            <b-form-group class="col-12 col-sm-6 col-md-4" id="form-title-group-name" label="Название новости">
              <b-form-input id="name"
                              type="text"
                              placeholder="Введите название"
                              v-model="form.name"
                              required>

              </b-form-input>
            </b-form-group>
            <b-form-group class="col-12 col-sm-6 col-md-4" id="form-title-group-date_active" label="Дата публикации">
              <b-form-input id="date_active"
                              type="datetime-local"
                              v-model="form.date_active"
                              required>

              </b-form-input>
            </b-form-group>
            <b-form-group class="col-12 col-sm-6 col-md-4" id="form-title-group-tags" label="Тэги">
              <b-form-input id="tags"
                              type="text"
                              placeholder="Введите тэги (через пробел)"
                              v-model="form.tags"
                              >

              </b-form-input>
            </b-form-group>
            <b-form-group class="col-12" id="form-title-group-preview" label="Краткое описание">
              <b-form-textarea id="preview"
                              type="text"
                              placeholder="Введите краткое описание новости"
                              v-model="form.preview"
                              required>

              </b-form-textarea>
            </b-form-group>
            <b-form-group class="col-12 col-sm-8" id="form-title-group-unit" label="Подразделения (несколько можно выбрать с зажатым ctrl)">
              <b-form-select id="unit"
                              type="text"
                              placeholder="Выберите подразделения код подразделения"
                              v-model="form.units"
                              :options="units"
                              multiple
                              :select-size="5"
                              >

              </b-form-select>
            </b-form-group>
            <b-form-group class="col-12 col-sm-4" id="form-title-group-push" label="Пуш">
              <b-form-select id="push"
                              type="text"
                              placeholder="Отправка пуша"
                              v-model="form.push"
                              :options="push"
                              required>
              </b-form-select>
            </b-form-group>
            <b-form-group class="col-12 col-sm-6 col-md-4" id="form-files" label="Фото для слайдера">
                <vue-upload-multiple-image
                  @upload-success="uploadImageSuccess"
                  @before-remove="beforeRemove"
                  @edit-image="editImage"
                  @data-change="dataChange"
                  :data-images="images"
                  dragText="Перетащите изображения"
                  browseText="Или выберите"
                  popupText="Загружено"
                  primaryText=""
                  markIsPrimaryText=""
                  :maxImage="100"
                  v-model="form.files"
                ></vue-upload-multiple-image>
            </b-form-group>
            <b-form-group class="col-12" id="form-title-group-body" label="Детальное описание">
              <editor api-key="4p3e4azt6iyrepdsgo6ulbbu4x39ewfbo4dd14ev3lyqo79e" :init="{plugins: 'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount', image_dimensions: false, image_prepend_url: 'http://45.86.181.86/', remove_script_host: false, height: 500}"
              v-model="form.body">
              </editor>
            </b-form-group>
          </div>
          <b-form-group id="form-title-group-pic" class="last_img" label="Изображение новости">
            <picture-input
              ref="pictureInput"
              width="200"
              height="200"
              margin="8"
              accept="image/*"
              size="10"
              :removable="true"
              :customStrings="{
                upload: '<h1>Перетащите изображение</h1>',
                drag: 'Перетащите изображение',
                change: 'Заменить фото',
                remove: 'Удалить фото',
              }"
              :zIndex="1000"
              :prefillOptions="{mediaType: 'image/*'}"
              :prefill="form.picture"
              v-observe-visibility="visibilityChanged"
              @change="changeImage"
            >
            </picture-input>
          </b-form-group>
          <cropper
              ref="croppedImage"
              classname="cropper"
              :src="img"
              :stencil-props="{
                  aspectRatio: 16/7
              }"
              @change="change"
            ></cropper>
          <!--b-form-group>
            <b-button @click="add_pic()" variant="primary">Добавить изображение</b-button>
          </b-form-group-->
          <b-button v-b-toggle.collapse-1 type="submit" variant="success">Отправить</b-button>
          <b-button v-b-toggle.collapse-1 type="reset" variant="">Отмена</b-button>
        </b-form>

      </b-collapse>
      <b-col lg="12" class="my-1">
        <b-form-group
          label="Фильтр"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Введите фразу/слово для поиска"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Очистить</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-table :sort-desc.sync="sortDesc" :sort-by.sync="sortBy" :busy="isBusy" id="news_table" :per-page="perPage" :current-page="currentPage" :filter="filter" striped hover :fields="fields" :items="items" table-variant="dark">
        <template v-slot:cell(id)="data">
          <span>{{data.value}}</span>
          <b-button v-if="data.item.status === 1" variant="success" @click="edit_item(data.value)" ref="btnEdit"><i class="fas fa-edit"></i></b-button>
          <b-button v-if="data.item.status === 1" variant="secondary" @click="delete_item(data.value)"><i class="fas fa-trash-alt"></i></b-button>
          <b-button v-if="data.item.status === 0" variant="success" @click="accept_item_status(data.value)" ref="btnEdit"><i class="fas fa-check"></i></b-button>
          <b-button v-if="data.item.status === 0" variant="danger" @click="delete_item(data.value)"><i class="fas fa-times"></i></b-button>
        </template>
        <template v-slot:cell(picture)="data">
          <img class="user-img" :src="data.value" alt="">
        </template>
      </b-table>
      <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="news_table"
            first-number
            last-number
            align="center"
            v-if="rows>perPage"
      ></b-pagination>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import alertMsg from '../common/AlertMsg';
import Editor from '@tinymce/tinymce-vue';
import PictureInput from 'vue-picture-input';
import { Cropper } from 'vue-advanced-cropper';
import VueUploadMultipleImage from 'vue-upload-multiple-image';

export default {
  props: {},
  components: {
    alertMsg,
    PictureInput,
    Editor,
    Cropper,
    VueUploadMultipleImage
  },
  data() {
    return {
      form: {
        name: '',
        preview: '',
        body: '',
        picture: '',
        date_active: '',
        tags: '',
        units: [],
        push: 'none',
        files: '',
      },
      sortBy: 'id',
      sortDesc: true,
      perPage: 15,
      isBusy: false,
      currentPage: 1,
      images: [],
      alertMessage: 'Error',
      showAlert: false,
      filter: null,
      edit: false,
      visible: false,
      units: [],
      push: [{value: "none", text: "Не отправлять"},{value: "instant", text: "Сразу же"}],
      img: '',
      cropImg: '',
      fields: [
        { key: 'picture', label: '', sortable: true },
        { key: 'name', label: 'Название', sortable: true },
        { key: 'author', label: 'Автор', sortable: true },
        { key: 'date_active', label: 'Дата публикации', sortable: true },
        { key: 'tags', label: 'Тэги', sortable: true },
        { key: 'id', label: '', sortable: true },
      ],
    };
  },
  computed: {
    ...mapState({
      user: state => state.id,
      host: state => state.host,
      items: state => state.news.items,
      editing_item: state => state.news.editing_item,
    }),
    isAuthorised() {
      return this.$store.getters.isAuthorised;
    },
    rows() {
      return this.items.length;
    }
  },
  methods: {
    ...mapActions({
      initItems: 'news/initItems', // -> this.initItems()
      initEditingItem: 'news/initEditingItem', // -> this.initEditingItem()
      alertMsg: 'alertMsg', // -> this.alertMsg()
    }),
    init() {
      const path = this.host + 'news';
      axios.post(path, {}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.initItems({
              items: res.data.news
            })
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          if (error.response.status === 401){
            this.$store.dispatch('init', { email: localStorage.email, id: '', token: '' });
            this.$router.push('/login');
          }
        });
    },
    delete_item(item_id) {
      const path = this.host + 'delete_news';
      axios.post(path, {'id': item_id}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.init();
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
          } else {
            this.alertMsg({
              alert: res.data.error,
              type: 'danger',
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    accept_item_status(item_id) {
      const path = this.host + 'accept_item_status';
      axios.post(path, {'id': item_id}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.init();
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
          } else {
            this.alertMsg({
              alert: res.data.error,
              type: 'danger',
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    edit_item(item_id) {
      this.initEditingItem({
        'index': item_id
      })
      this.visible = true;
      this.initForm_edit();
      window.scrollTo(0,0);
    },
    visibilityChanged () {
      window.dispatchEvent(new Event('resize'));
    },
    addItem(payload) {
      this.showAlert = false;
      const path = this.host + 'add_news';
      axios.post(path, payload, { headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.$emit('news');
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
            this.initForm();
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    editItem(payload) {
      this.showAlert = false;
      const path = this.host + 'edit_news';
      axios.post(path, payload, { headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.$emit('news');
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
            this.initForm();
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    add_pic(){
      //$('.last_img').css('background', '#eee')
    },
    change({coordinates, canvas}) {
      this.cropImg = canvas.toDataURL();
      // eslint-disable-next-line
      console.log(coordinates, canvas);
    },
    changeImage() {
      this.img = this.$refs.pictureInput.image;
    },
    uploadImageSuccess(formData, index, fileList) {
      // eslint-disable-next-line
      console.log('data', formData, index, fileList)
      this.images = fileList;
    },
    beforeRemove (index, done, fileList) {
      // eslint-disable-next-line
      console.log('index', index, fileList)
      let removeBool = confirm("remove image")
      if (removeBool === true) { done(); }
    },
    editImage (formData, index, fileList) {
      // eslint-disable-next-line
      console.log('edit data', formData, index, fileList)
    },
    dataChange (data) {
      // eslint-disable-next-line
      console.log(data)
    },
    take_units() {
      this.showAlert = false;
      const path = this.host + 'take_unit_names';
      axios.post(path, {}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.units = res.data.units;
            this.units.unshift({text: 'Отправить всем', value: 'sendAll'});
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (this.edit){
        let payload = {
          'id' : this.editing_item.id,
          'name' : this.form.name,
          'preview' : this.form.preview,
          'body' : this.form.body,
          'tags' : this.form.tags,
          'date_active' : this.form.date_active,
          'units' : this.form.units,
          'push' : this.form.push,
          'files' : this.images,
        };
        if (this.$refs.pictureInput.file !== undefined) {
          payload.photo = this.cropImg;
          payload.photo_name = this.$refs.pictureInput.file.name;
        }
        this.editItem(payload);
      }else{
        let payload = {
          'name' : this.form.name,
          'preview' : this.form.preview,
          'body' : this.form.body,
          'tags' : this.form.tags,
          'author' : this.user,
          'date_active' : this.form.date_active,
          'units' : this.form.units,
          'push' : this.form.push,
          'files' : this.images,
        };
        if (this.$refs.pictureInput.file !== undefined) {
          payload.photo = this.cropImg;
          payload.photo_name = this.$refs.pictureInput.file.name;
        }
        this.addItem(payload);
      }
      this.init();
    },
    onReset(evt) {
      evt.preventDefault();
      this.initForm();
    },
    initForm() {
      this.edit = false;
      this.form.name = '';
      this.form.preview = '';
      this.form.body = '';
      this.form.tags = '';
      this.form.date_active = '';
      this.form.picture = '';
      this.form.units = [];
      this.form.push = 'instant';
    },
    initForm_edit() {
      this.edit = true;
      this.form.name = this.editing_item.name;
      this.form.preview = this.editing_item.preview;
      this.form.body = this.editing_item.body;
      this.form.tags = this.editing_item.tags;
      let date = new Date(this.editing_item.date_active)
      this.form.date_active = date.toISOString().slice(0,-8);
      this.form.picture = this.editing_item.picture;
      this.form.units = this.editing_item.units?JSON.parse(this.editing_item.units):[];
      this.form.push = 'none';
    },
  },
  created() {
    this.take_units();
    this.init();
  },
  mounted() {
    this.$root.$on('bv::modal::shown', () => {
      this.$nextTick(function () {
        // DOM updated
        window.dispatchEvent(new Event('resize'));
      });
    })
  },
};
</script>

<style>
  .user-img{
    max-width: 50px;
    max-height: 50px;
  }
  .head-group{
    margin-bottom: 15px;
  }
  .cropper {
    margin-bottom: 15px;
  }
  [type="submit"] {
    margin-right: 10px;
  }
</style>
