<template>
  <nav class="col-md-4 col-lg-3 d-none d-md-block bg-light sidebar">
    <div class="sidebar-sticky">
      <ul class="nav flex-column">
        <li class="nav-item" v-bind:key="item.id" v-for="item in menu">
          <router-link class="nav-link" v-bind:to="item.link">{{ item.text }}</router-link>
        </li>
      </ul>
      <files-table v-if="isAuthorised"/>
    </div>
  </nav>
</template>

<script>
import FilesTable from './FilesTable.vue';
export default {
  name: 'sidenav',
  components: {
    FilesTable
  },
  data() {
    return {
      menu: [
        {
          text: 'Новости',
          link: '/news',
        },
        {
          text: 'Объявления',
          link: '/actual_news',
        },
        {
          text: 'ЧаВО',
          link: '/faq',
        },
        {
          text: 'Документы',
          link: '/docs',
        },
        {
          text: 'Пользователи',
          link: '/users',
        },
        {
          text: 'Подразделения',
          link: '/units',
        },
        {
          text: 'Медиатека',
          link: '/media'
        },
        {
         text: 'Контакты',
          link: '/contacts',
        },
        {
         text: 'Группы контактов',
          link: '/contact_groups',
        },
        {
          text: 'Газеты/журналы',
          link: '/massmedia',
        },
        {
          text: 'Мероприятия',
          link: '/events',
        },
        {
          text: 'Опросы',
          link: '/quiz',
        },
      ],
    };
  },
  computed: {
    isAuthorised() {
      return this.$store.getters.isAuthorised;
    },
  },
};
</script>

<style scoped>
  .nav{
    padding: 15px 0;
  }
  .add_event {
    width: 50%;
    margin: 0 15px;
  }
</style>
