<template>
    <b-col cols="9" md="10" class="stats col-lg-9 col-md-8 ml-sm-auto px-4">
      <h1>Статистика</h1>
      <b-row>
        <b-col cols="12" md="6" lg="4" class="stat-info" v-for="item in items" v-bind:key="item.id">
          <div class="content">
            <div class="header">{{ item.name }}</div>
            <hr>
            <div class="value">{{ item.value }}</div>
          </div>
        </b-col>
        <b-col cols="12" class="stat-info">
          <div class="content">
            <div class="header">Количество новостей за месяц</div>
            <hr>
            <div class="chart value"><line-chart :chartData="data" :options="options"></line-chart></div>
          </div>
        </b-col>
      </b-row>
    </b-col>
</template>

<script>
import LineChart from './Line.js';
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  name: 'stats',
  components: {
    LineChart
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            ticks: {
              fontColor: "#eaeaea",
              stepSize: 1,
              beginAtZero: true
            },
            gridLines: {
              display: true,
              color: '#fafafa66',
            },
          }],
          yAxes: [{
            ticks: {
              fontColor: "#eaeaea",
              stepSize: 1,
              beginAtZero: true
            },
            gridLines: {
              display: true,
              color: '#fafafa66',
            }
          }]
        },
        legend: {
          labels: {
            fontColor: "white",
            fontSize: 18
          }
        },
      },
      news_data: [],
      items: [],
      labels: [],
      data: {},
    }
  },
  computed: {
    ...mapState({
      host: state => state.host,
    }),
  },
  methods: {
    init(){
      const path = this.host + 'stats';
      axios.post(path, {}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.items.push({'name': 'Количество пользователей', 'value': res.data.user_count});
            this.items.push({'name': 'Количество подразделений', 'value': res.data.unit_count});
            this.items.push({'name': 'Количество новостей', 'value': res.data.news_count});
            this.items.push({'name': 'Количество вопросов', 'value': res.data.faq_count});
            this.items.push({'name': 'Самое большое подразделение', 'value': res.data.biggest_unit});
            this.items.push({'name': 'Самый активный автор', 'value': res.data.most_active_author});
            this.labels = res.data.dates;
            this.news_data = res.data.counts;
            this.data = {
                labels: this.labels,
                datasets: [
                  {
                    label: 'Новости',
                    data: this.news_data,
                    backgroundColor: '#dc354566',
                  }
                ],
              }
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          if (error.response.status === 401){
            this.$store.dispatch('init', { email: localStorage.email, id: '', token: '' });
            this.$router.push('/login');
          }
        });
    },
  },
  created() {
    this.init()
  }
};
</script>

<style scoped>
.stat-info .content {
  margin: 10px -5px;
  box-shadow: 0 0 10px #343b40;
  padding: 25px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #343b40;
}
.stat-info .content .header{
  font-weight: light;
  font-size: 1.5em;
  text-align: center;
}
.stat-info .content .value{
  font-weight: bold;
  color: #dc3545;
  font-size: 1.6em;
  text-align: center;
}
.stat-info .content .value.chart{
  width: 100%
}
.stat-info .content hr{
  margin: 5px;
  border-top: 2px solid rgb(247, 12, 12);
  width: 25%;
}
</style>
