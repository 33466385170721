<template>
  <div id="app">
    <title>Собака</title>
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.2.0/css/all.css">
    <navbar></navbar>
    <div class="container-fluid">
      <div class="row">
        <sidenav></sidenav>
        <transition name="fade"  mode="out-in">
          <router-view/>
        </transition>
      </div>
    </div>
    <modal-login></modal-login>
    <alert></alert>
  </div>
</template>

<script>
import navbar from './components/common/Navbar';
import sidenav from './components/common/Sidenav';
import modal from './components/common/Modal';
import alertGlobal from './components/common/AlertGlobal';
import VueMeta from 'vue-meta';
import Vue from 'vue';
Vue.use(VueMeta)

export default {
  name: 'App',
   metaInfo: {
      title: 'Панель управления приложением',
  },
  components: {
    navbar,
    sidenav,
    'modal-login': modal,
    'alert': alertGlobal,
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,200;0,300;0,500;0,700;1,400&display=swap');
#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #eaeaea;
  padding-top: 60px;
}
.table > tbody > tr > td {
  vertical-align: middle;
}
body {
  font-size: .875rem;
  background-color: #50555a;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
  text-align: left;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1), 0 0 5px #343b40;
  background-color: #292e33;
  color: #eaeaea;
}

.sidebar-sticky {
  background-color: #343a40;
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #eaeaea;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Content
 */

[role="main"] {
  padding-top: 133px; /* Space for fixed navbar */
}

@media (min-width: 768px) {
  [role="main"] {
    padding-top: 48px; /* Space for fixed navbar */
  }
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
tr > td > button:first-child {
  margin-right: 5px !important;
}
tr > td > button:nth-child(3){
  margin-left: 5px !important;
}
.page-item.active .page-link {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
.page-link {
  color: #28a745 !important;
}
</style>