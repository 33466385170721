export default {
    namespaced: true,
    state: {
      items: [],
    },
    mutations: {
      initItems(state, mutationData) {
        state.items = mutationData.items;
      },
      initEditingItem(state, mutationData) {
        let index = 0;
        state.items.forEach(function(item, i) {
          if (item.id == mutationData.index) index = i;
        });
        state.editing_item = state.items[index];
      },
    },
    actions: {
      initItems ({ commit }, data) {
        commit('initItems', data)
      },
      initEditingItem ({ commit }, data) {
        commit('initEditingItem', data)
      },
    },
  }
  