<template>
  <div>
    <b-alert class="bumble-alert" :variant="alertType" show v-if="showAlert">{{ message }}</b-alert>
    <br>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'alertMsgGlobal',
  computed: {
    ...mapState({
      message: state => state.alert,
      showAlert: state => state.showAlert,
      alertType: state => state.alertType,
    }),
  },
};
</script>

<style>
  .bumble-alert{
    position: fixed;
    bottom: 20px;
    right: 20px;
    max-width: 200px;
    opacity: .8;
  }
</style>
