import Vue from 'vue';
import Vuex from 'vuex';
import users from './users/users.js'
import units from './units/units.js'
import faq from './faq/faq.js'
import docs from './docs/docs.js'
import files from './files/files.js'
import news from './news/news.js'
import actual_news from './actual_news/actual_news.js'
import media from './media/media.js'
import contacts from './contacts/contacts.js'
import contact_groups from './contact_groups/contact_groups.js'
import massmedia from './massmedia/massmedia.js'
import issues from './issues/issues.js'
import events from './events/events.js'
import quizes from './quiz/quiz.js'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    email: localStorage.email || '',
    id: localStorage.id || '',
    token: localStorage.token || '',
    header: '«ЛУКОЙЛ-ПЕРМЬ»',
    alert: '',
    showAlert: false,
    alertType: 'success',
    // host: 'http://localhost:8080/ajax/',
    host: 'https://lukoil.oppolukperm.ru/ajax/',
  },
  mutations: {
    init(state, mutationData) {
      localStorage.email = state.email = mutationData.email || '';
      localStorage.id = state.id = mutationData.id || '';
      localStorage.token = state.token = mutationData.token || '';
    },
    clear(state) {
      state.id = '';
      localStorage.id = '';
      state.token = '';
      localStorage.token = '';
    },
    alert(state, mutationData) {
      state.alert = mutationData.alert;
      state.showAlert = mutationData.showAlert;
      state.alertType = mutationData.alertType;
    }
  },
  getters: {
    isAuthorised: state => state.id !== '',
  },
  actions: {
    alertMsg ({ commit }, data) {
      commit('alert', {'alert': data.alert, showAlert: true, alertType: data.type })
      setTimeout(() => {
        commit('alert', {'alert': '', showAlert: false, alertType: 'success' })
      }, 5000)
    },
    init ({ commit }, data) {
      setTimeout(() => {
        commit('init', data)
      }, 500)
    }
  },
  modules: {
    users,
    units,
    faq,
    docs,
    files,
    news,
    actual_news,
    media,
    contacts,
    contact_groups,
    massmedia,
    issues,
    events,
    quizes,
  }
});
