<template>
  <b-modal ref="addItemModal"
           id="modal-add-question"
           title="Добавить вопрос"
           hide-footer>
      <b-form @submit="onSubmit" @reset="onReset" class="w-100">
      <alertMsg :message="alertMessage" v-if="showAlert"></alertMsg>
      <b-form-group id="form-title-group-name" label="Вопрос">
        <b-form-input id="name"
                        type="text"
                        placeholder="Введите вопрос"
                        v-model="addForm.question"
                        required>

        </b-form-input>
      </b-form-group>
      <b-form-group id="form-title-group-link" label="Варианты ответа">
        <b-form-input id="link"
                        type="text"
                        placeholder="Введите варианты ответа (через /)"
                        v-model="addForm.options"
                        required>

        </b-form-input>
      </b-form-group>
      <b-form-group id="form-title-group-mass_media" label="Опрос">
        <b-form-select id="group"
                        type="text"
                        placeholder="Выберите опрос"
                        v-model="addForm.quiz"
                        :options="groups"
                        required>

        </b-form-select>
      </b-form-group>
      <b-button type="submit" variant="success">Добавить</b-button>
      <b-button type="reset" variant="">Отмена</b-button>
    </b-form>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import alertMsg from '../common/AlertMsg';

export default {
  components: {
    alertMsg,
  },
  name: 'modalAdd',
  data() {
    return {
      addForm: {
        question: '',
        options: '',
        quiz: '',
      },
      alertMessage: 'Error',
      showAlert: false,
      groups: [],
    };
  },
  computed: {
    ...mapState({
      host: state => state.host,
    }),
    isAuthorised() {
      return this.$store.getters.isAuthorised;
    },
  },
  methods: {
    ...mapActions([
      'alertMsg', // -> this.alertMsg()
    ]),
    addItem(payload) {
      this.showAlert = false;
      const id = 'modal-add-question';
      const path = this.host + 'add_question';
      axios.post(path, payload, { headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.$bvModal.hide(id);
            this.$emit('question');
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
            this.initForm();
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    take_groups() {
      this.showAlert = false;
      const path = this.host + 'take_quizes';
      axios.post(path, {}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.groups = res.data.quizes;
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      let payload = {
        'question' : this.addForm.question,
        'options' : this.addForm.options,
        'quiz_id' : this.addForm.quiz
      };
      this.addItem(payload);
    },
    onReset(evt) {
      evt.preventDefault();
      this.$bvModal.hide('modal-add-quiz');
      this.initForm();
    },
    initForm() {
      this.addForm.question = '';
      this.addForm.options = '';
      this.addForm.quiz = '';
    },
  },
  created() {
    this.take_groups();
  },
};
</script>

<style scoped>

</style>
