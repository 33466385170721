<template>
  <b-modal ref="addItemModal"
           id="modal-add-user"
           title="Добавить пользователя"
           hide-footer>
      <b-form @submit="onSubmit" @reset="onReset" class="w-100">
      <alertMsg :message="alertMessage" v-if="showAlert"></alertMsg>
      <b-form-group id="form-title-group-name">
        <b-form-input id="name"
                        type="text"
                        placeholder="Введите Имя"
                        v-model="addForm.name"
                        required>

        </b-form-input>
      </b-form-group>
      <b-form-group id="form-title-group-patronymic">
        <b-form-input id="patronymic"
                        type="text"
                        placeholder="Введите отчество"
                        v-model="addForm.patronymic"
                        required>

        </b-form-input>
      </b-form-group>
      <b-form-group id="form-title-group-surname">
        <b-form-input id="surname"
                        type="text"
                        placeholder="Введите фамилию"
                        v-model="addForm.surname"
                        required>

        </b-form-input>
      </b-form-group>
      <b-form-group id="form-title-group-phone">
        <b-form-input id="phone"
                        type="tel"
                        placeholder="Введите телефон"
                        v-model="addForm.phone"
                        required>

        </b-form-input>
      </b-form-group>
      <b-form-group id="form-title-group-email">
        <b-form-input id="email"
                        type="email"
                        placeholder="Введите почту"
                        v-model="addForm.email"
                        required>

        </b-form-input>
      </b-form-group>
      <b-form-group id="form-title-group-pass">
        <b-form-input id="password"
                        type="password"
                        placeholder="Введите пароль"
                        v-model="addForm.password"
                        required>

        </b-form-input>
      </b-form-group>
      <b-form-group id="form-title-group-pic">
        <picture-input ref="pictureInput" width="200" height="200" margin="8" accept="image/jpeg,image/png" size="10"
          :removable="true" :customStrings="{
          upload: '<h1>Перетащите изображение</h1>',
          drag: 'Перетащите изображение',
          change: 'Заменить фото',
          remove: 'Удалить фото',
        }">
        </picture-input>
      </b-form-group>
      <b-form-group id="form-title-group-unit" label="Подразделение">
        <b-form-select id="unit"
                        type="text"
                        placeholder="Введите код подразделения"
                        v-model="addForm.unit"
                        :options="units"
                        required>

        </b-form-select>
      </b-form-group>
      <b-form-group id="form-title-group-group" label="Группа">
        <b-form-select id="group"
                        type="text"
                        placeholder="Группа пользователей"
                        v-model="addForm.group"
                        :options="groups"
                        required>

        </b-form-select>
      </b-form-group>
      <b-button type="submit" variant="success">Добавить</b-button>
      <b-button type="reset" variant="">Отмена</b-button>
    </b-form>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import alertMsg from '../common/AlertMsg';
import PictureInput from 'vue-picture-input';

export default {
  components: {
    alertMsg,
    PictureInput,
  },
  name: 'modalAdd',
  data() {
    return {
      addForm: {
        email: '',
        password: '',
        name: '',
        patronymic: '',
        surname: '',
        phone: '',
        unit: '',
        group: '',
        photo: '',
        file: '',
      },
      alertMessage: 'Error',
      showAlert: false,
      units: [],
      groups: [],
    };
  },
  computed: {
    ...mapState({
      host: state => state.host,
    }),
    isAuthorised() {
      return this.$store.getters.isAuthorised;
    },
  },
  methods: {
    ...mapActions([
      'alertMsg', // -> this.alertMsg()
    ]),
    addItem(payload) {
      this.showAlert = false;
      const id = 'modal-add-user';
      const path = this.host + 'add_user';
      axios.post(path, payload, { headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.$bvModal.hide(id);
            this.$emit('users');
            this.alertMsg({
              alert: res.data.message,
              type: 'success',
            });
            this.initForm();
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    take_units() {
      this.showAlert = false;
      const path = this.host + 'take_units';
      axios.post(path, {}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.units = res.data.units;
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    take_groups() {
      this.showAlert = false;
      const path = this.host + 'take_groups';
      axios.post(path, {}, { headers: { Authorization: `Bearer ${localStorage.token}` } })
        .then((res) => {
          if (res.data.status === 'success') {
            this.groups = res.data.groups;
          } else {
            this.alertMessage = `${res.data.status}: ${res.data.error}`;
            this.showAlert = true;
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      let payload = {
        'email' : this.addForm.email,
        'password' : this.addForm.password,
        'name' : this.addForm.name,
        'patronymic' : this.addForm.patronymic,
        'surname' : this.addForm.surname,
        'phone' : this.addForm.phone,
        'unit' : this.addForm.unit,
        'group' : this.addForm.group,
        'photo' : this.$refs.pictureInput.image,
        'photo_name' : this.$refs.pictureInput.file.name,
      };
      this.addItem(payload);
    },
    onReset(evt) {
      evt.preventDefault();
      this.$bvModal.hide('modal-add');
      this.initForm();
    },
    initForm() {
      this.addForm.email = '';
      this.addForm.password = '';
      this.addForm.name = '';
      this.addForm.patronymic = '';
      this.addForm.surname = '';
      this.addForm.phone = '';
      this.addForm.photo = '';
    },
  },
  created() {
    this.take_groups();
    this.take_units();
  },
};
</script>

<style scoped>

</style>
